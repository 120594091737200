import { Redirect } from "react-router-dom"
import styled from "styled-components"

import { LoginForm } from "src/components/Login/LoginForm"
import { resetPasswordUrl } from "src/constants/hrefs"
import { useAppData } from "src/context/useAppData"
import { redirectToAuthService } from "src/data/auth/auth"
import { storedLocation } from "src/data/auth/authStorage"
import { useFetchInvitationInfo } from "src/data/organizations/queries/inviteQueries"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { getInviteCodeFromUrl } from "src/router/organizationRoutes"
import { Routes } from "src/router/routes"
import { colorsV2 } from "src/ui/colors"
import Logo from "src/ui/icons/logo.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import {
  locationWithOrgId,
  mergeQueryParams,
} from "src/ui/Link/internalLinkUtil"
import { LoadingScreen } from "src/ui/LoadingScreen/LoadingScreen"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { debug } from "src/utils/logger"

export function Login() {
  const { oauthEnabled } = useFlags()
  const { authState, activeOrg, user, loading } = useAppData()
  const authenticated = !!authState.authorization
  const userLoggedIn = !!user && !loading

  const location = useAppLocation()

  const { t, langKeys } = useTranslate()

  const inviteCode = getInviteCodeFromUrl()
  const title = inviteCode
    ? t(langKeys.organizations_team_join)
    : t(langKeys.welcome)

  const fetchInvitationInfo = useFetchInvitationInfo({
    inviteCode: inviteCode || "",
    options: {
      enabled: !!inviteCode,
    },
  })

  const invitationinfo = fetchInvitationInfo.data

  if (fetchInvitationInfo.isInitialLoading) {
    return (
      <LoadingScreen
        debugInfo={<div>Fetching invitation information ...</div>}
      />
    )
  }

  if (oauthEnabled && !authenticated) {
    // If enabled by accident, open http://localhost:8001?oauthEnabled=off
    redirectToAuthService(location)
    return (
      <LoadingScreen
        debugInfo={<div>Redirecting to OAuth login page...</div>}
      />
    )
  }

  if (userLoggedIn) {
    const storedLocationData = storedLocation.pop()
    const baseLocation = {
      ...(storedLocationData ?? Routes.Dashboard.location()),
      // We need to keep search if present:
      search: mergeQueryParams(location.search, storedLocationData?.search),
    }
    const redirectLocation = locationWithOrgId({
      orgId: activeOrg?.id,
      location: baseLocation,
    })

    debug.log(`${location.pathname} redirecting to`, redirectLocation)
    return <Redirect to={redirectLocation} />
  }

  return (
    <Box>
      <LoginLayoutBox>
        <FormBox>
          <LogoBox>
            <Logo width={90} />
          </LogoBox>

          <MText variant="heading3" marginBottom={spacing.L} textAlign="center">
            {title}
          </MText>

          <LoginForm prefillEmail={invitationinfo?.invitation_email} />

          <AccountSetup>
            <MText variant="bodyS" color="secondary">
              {!invitationinfo?.invitation_email && (
                <p>
                  {t(langKeys.sign_in_no_account)}{" "}
                  <InternalLink to={{ ...location, pathname: "/signup" }}>
                    {t(langKeys.create_an_account)}
                  </InternalLink>
                </p>
              )}

              <p>
                <ExternalLink
                  href={resetPasswordUrl()}
                  showExternalIcon={false}
                >
                  {t(langKeys.forgot_password)}
                </ExternalLink>
              </p>
            </MText>
          </AccountSetup>
        </FormBox>
      </LoginLayoutBox>
    </Box>
  )
}

const Box = styled.div`
  min-height: 100vh;
`

const Title = styled.h1`
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: ${spacing.XL};
`

const LoginLayoutBox = styled.main`
  display: flex;
  min-height: calc(100vh - 100px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FormBox = styled.div`
  width: 100%;
  max-width: 480px;
  padding: ${spacing.XL} ${spacing.M};
  font-size: 0.875rem;
`

const LogoBox = styled.div`
  text-align: center;
  margin-bottom: ${spacing.L};
`

const AccountSetup = styled.div`
  margin-top: ${spacing.XL};
  text-align: center;
  border-top: 1px solid ${colorsV2.divider};
`
